import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransFn } from '../types/general';

export interface HeaderState {
  text: string | TransFn;
  additionalText: string | TransFn | null;
  isRoot: boolean;
  buttonText: string | null;
  buttonDest: string | null;
  noGlobalSearch?: boolean;
}

const initialState: HeaderState = {
  text: '',
  additionalText: null,
  isRoot: true,
  buttonText: null,
  buttonDest: null,
  noGlobalSearch: false,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<HeaderState>) => action.payload,
  },
});

export const { setHeader } = headerSlice.actions;

export default headerSlice.reducer;
