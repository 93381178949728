import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { __ } from '../helpers/i18n';

export interface AppModalState {
  uuid: string;
  title: string;
  text: string | ReactNode;
  cancelText: string;
  submitText: string;
  isOpened: boolean;
  isResolved: boolean;
  isSubmitted: boolean;
  isRejected: boolean;
}

interface SetContentProps {
  title: string;
  text: string | ReactNode;
  cancelText?: string;
  submitText?: string;
}

const initialState: AppModalState = {
  uuid: '',
  title: '',
  text: '',
  cancelText: __('action.cancel'),
  submitText: __('action.submit'),
  isOpened: false,
  isResolved: false,
  isSubmitted: false,
  isRejected: false,
};

const appModalSlice = createSlice({
  name: 'appModal',
  initialState,
  reducers: {
    setIsOpened: (state, action) => {
      state.isOpened = action.payload.isOpened;
      state.uuid = action.payload.uuid;
    },
    setAfterResolving: (state) => {
      state.isResolved = true;
      state.isSubmitted = true;
    },
    setAfterRejected: (state) => {
      state.isResolved = true;
      state.isRejected = true;
    },
    setContent: (state, action: PayloadAction<SetContentProps>) => {
      state.title = action.payload.title;
      state.text = action.payload.text;
      if (action.payload.cancelText) state.cancelText = action.payload.cancelText;
      if (action.payload.submitText) state.submitText = action.payload.submitText;
    },
    clearResolving: (state) => initialState,
  },
});

export const { setIsOpened, setAfterResolving, setContent, clearResolving, setAfterRejected } =
  appModalSlice.actions;

export default appModalSlice.reducer;
