export enum PlaceStatus {
  WAITING = 'WAITING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  BLOCKED = 'BLOCKED',
}

export const ValidPlaceStatusChanges: Record<PlaceStatus, PlaceStatus[]> = {
  [PlaceStatus.WAITING]: [PlaceStatus.ACCEPTED, PlaceStatus.REJECTED],
  [PlaceStatus.REJECTED]: [PlaceStatus.ACCEPTED],
  [PlaceStatus.ACCEPTED]: [PlaceStatus.BLOCKED],
  [PlaceStatus.BLOCKED]: [PlaceStatus.ACCEPTED],
};
