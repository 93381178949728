import { AxiosResponse } from 'axios';
import { ApiErrorCode } from 'app-types';
import { errorNotification } from './handleNotifications';
import { getErrorMessage } from './getErrorMessage';

export const handleError = (response: AxiosResponse | undefined): void => {
  if (!response) {
    errorNotification(getErrorMessage(ApiErrorCode.OtherError));
    return;
  }

  errorNotification(getErrorMessage(response.data.error_code));
};
