import { FC } from 'react';
import { Center } from '@chakra-ui/react';
import { __ } from '../../../../helpers/i18n';
import { Logo } from '../../Logo';

export const AppOffline: FC = () => (
  <Center height="100vh" textAlign="center">
    <div>
      <Logo />
      <h1>
        <strong>{__('error.youAreUsingThisAppInOfflineMode')}</strong>
      </h1>
      <p>{__('error.connectToInternetToAccessApp')}</p>
    </div>
  </Center>
);
