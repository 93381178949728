import { createStandaloneToast } from '@chakra-ui/react';
import { theme } from '../../../config/theme';

type toastStatuses = 'success' | 'error' | 'info' | 'warning';

export const notification = (title: string, status: toastStatuses): void => {
  const toast = createStandaloneToast({ theme });

  if (toast) {
    toast({
      title,
      status,
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
  }
};

export const persistentNotification = (title: string, status: toastStatuses): void => {
  const toast = createStandaloneToast({ theme });

  toast({
    title,
    status,
    isClosable: true,
    duration: null,
    position: 'top',
  });
};
