import { FC, Suspense, lazy, useState, useLayoutEffect } from 'react';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { setWindowState } from './utils/windowState';
import { OnlineContent } from './components/Common/OnlineContent';
import { AppSpinner } from './components/Common/AppSpinner';
import { OfflineContent } from './components/Common/OfflineContent';
import { AppOffline } from './components/Common/OfflineContent/AppOffline';
import { store, persistor } from './config/store';
import { theme } from './config/theme';
import { i18n } from './helpers/i18n';
import { getClientLanguage } from './utils/language';
import 'flatpickr/dist/themes/airbnb.css';
import { RouteAuthorized } from './components/Routes/Authorized';
import { StyledFlatpickr } from './config/global';

export const history = createBrowserHistory({ basename: '/' });

const ChooseObject = lazy(() => import('./views/ChooseObject'));
const PageError = lazy(() => import('./views/PageError'));
const Page404 = lazy(() => import('./views/Page404'));
const Login = lazy(() => import('./views/Login'));
const RegistrationRoutes = lazy(() => import('./views/Registration'));
const RestorePass = lazy(() => import('./views/RestorePass'));
const SetPassword = lazy(() => import('./views/SetPassword'));
const User = lazy(() => import('./views/User'));
const Dashboard = lazy(() => import('./views/Dashboard'));
const AddObject = lazy(() => import('./views/AddObject'));
const ConfirmPhoneNumber = lazy(() => import('./views/Registration/ConfirmPhoneNumber'));
const Partners = lazy(() => import('./views/Partners'));
const Card = lazy(() => import('./views/Card'));
const VerificationPaymentFinished = lazy(() => import('./views/VerificationPaymentFinished'));
const UserActivated = lazy(() => import('./views/UserActivated'));
const RulesConfirmation = lazy(() => import('./views/Registration/RulesConfirmation'));
const AfterRegister = lazy(() => import('./views/AfterRegister'));

export const App: FC = () => {
  const [i18nLoaded, setI18nLoaded] = useState(i18n.loaded);
  const reloadActiveTranslations = () => {
    setI18nLoaded(true);
  };

  useLayoutEffect(() => {
    const lang = store.getState().lang.current ?? getClientLanguage();
    i18n.load(lang, reloadActiveTranslations).then();
  }, [setI18nLoaded]);

  return (
    <Provider store={store}>
      <ChakraProvider theme={theme} resetCSS>
        {i18nLoaded ? (
          <PersistGate loading={null} persistor={persistor}>
            <OnlineContent>
              {/*   <UpdateInfoBar /> */}
              <Router history={history}>
                <Suspense fallback={<AppSpinner fullScreen />}>
                  <StyledFlatpickr />
                  <AppRouting />
                </Suspense>
              </Router>
              {/* <Modal /> */}
            </OnlineContent>
            <OfflineContent>
              <AppOffline />
            </OfflineContent>
          </PersistGate>
        ) : (
          <AppSpinner fullScreen={!i18nLoaded} />
        )}
      </ChakraProvider>
    </Provider>
  );
};

export const AppRouting: FC = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route path="/dashboard" component={Dashboard} />
    <RouteAuthorized path="/choose-object" component={ChooseObject} />
    <Route path="/rules-confirmation" component={RulesConfirmation} />
    <Route path="/error" component={PageError} />
    <Route path="/404" component={Page404} />
    <Route path="/login" component={Login} />
    <Route path="/registration" component={RegistrationRoutes} />
    <Route path="/auth" component={RestorePass} />
    <Route path="/set-pass/:id/:token" component={SetPassword} />
    <Route path="/user" component={User} />
    <RouteAuthorized path="/add-object" component={AddObject} />
    <Route path="/confirm-phone-number" component={ConfirmPhoneNumber} />
    <Route path="/partners" component={Partners} />
    <Route path="/card/:placeId" component={Card} />
    <Route path="/verification-payment-finished" component={VerificationPaymentFinished} />
    <Route path="/user-activated" component={UserActivated} />
    <Route path="/after-register" component={AfterRegister} />
    <Route render={() => <Redirect to="/404" />} />
  </Switch>
);

setWindowState();
