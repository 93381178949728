import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { history } from '../App';

export const redirectToLogin = (): void => history.push('/');

export const initializeAxios = (config: AxiosRequestConfig): AxiosInstance => {
  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.request.use(
    (c) => c,
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        redirectToLogin();
      }
      return error;
    },
  );

  return axiosInstance;
};
