import {
  FileEntity,
  FileRes,
  Gender,
  Language,
  LoginAttemptEntity,
  OperationStatus,
  OrderEntity,
  OwnerStatus,
  PlaceEntity,
  PlacePrivilege,
  PlacePrivilegesShortData,
  Role,
  UserCompany,
} from 'types';

export interface UserEntity {
  mail: string;
  places: PlaceEntity[];
  name: string | null;
  nip: string | null;
  surname: string | null;
  gender: Gender | null;
  passwordHash: string | null;
  phoneNumber: string | null;
  isUserActivated: boolean;
  isPhoneNumberActivated: boolean;
  ownerStatus: OwnerStatus | null;
  registeredAt: Date;
  mailActivationToken: string;
  phoneNumberActivationToken: string;
  language: Language | null;
  termsAccepted: boolean;
  privacyPolicyAccepted: boolean;
  marketingAgreementAccepted: boolean;
  birthDate: Date | null;
  avatar: FileEntity | null;
  company: UserCompany | null;
  dataRemovedAt: Date | null;
  role: Role;
  fbUserId: string | null;
  fbAccessToken: string | null;
  googleAccessToken: string | null;
  orders: OrderEntity[];
  fcmDeviceId: string | null;
  registrationType: RegistrationType;
  sourceComment: string | null;
  loginAttempts: LoginAttemptEntity[];
  setPassTokenHash: string | null;
}

export interface UserRes {
  id: string;
  mail: string;
  placePrivileges: PlacePrivilegesShortData[];
  name: string;
  nip: string | null;
  language: Language | null;
  surname: string;
  gender: Gender | null;
  phoneNumber: string | null;
  registeredAt: Date;
  isUserActivated: boolean;
  ownerStatus: OwnerStatus;
  termsAccepted: boolean;
  privacyPolicyAccepted: boolean;
  marketingAgreementAccepted: boolean;
  birthDate: Date | null;
  avatar: FileRes | null;
  company: UserCompany;
  isPhoneNumberActivated: boolean;
  needsPhoneNumberActivation: boolean;
  role: Role;
  registrationType: RegistrationType;
}

export type RegistrationResponse = {
  message: string;
};

export type LoggedUserResponse = {
  user: UserRes;
  jwt: string;
  refreshJwt: string;
};

export interface LogoutMessage {
  logoutSuccessful: boolean;
}

export interface CreateOwnerAccReq {
  name: string;
  surname: string;
  nip: string;
  phoneNumber?: string | null;
  country?: string | null;
  mail: string;
  password: string;
  password2: string;
  language?: Language;
}

export interface CreateOwnerAccByAdminReq {
  name: string;
  surname: string;
  nip: string;
  phoneNumber?: string | null;
  country?: string | null;
  mail: string;
  language?: Language;
}

export enum PromotionStatus {
  WAITING = 'WAITING',
  REJECTED = 'BLOCKED',
  STARTED = 'ACTIVE',
}

export interface OwnerTableRes {
  id: string;
  name: string;
  mail: string;
  phoneNumber: {
    value: string;
    isActivated: boolean;
  };
  numberOfPlaces: number;
  surname: string;
  ownerStatus: OwnerStatus;
  nip: string;
}

export interface ResetPassReq {
  password: string;
  userId: string;
  setPassToken: string;
}

export interface OwnerDetailsRes {
  id: string;
  name: string;
  surname: string;
  nip: string;
  mail: string;
  phoneNumber: string | null;
  ownerStatus: OwnerStatus;
  places: PlaceEntity[];
  isUserActivated: boolean;
}

export interface UpdateOwnerDetailsReq {
  name: string;
  surname: string;
  phoneNumber?: string;
  mail: string;
  nip: string;
}

export interface ChangeOwnerMail {
  mailActivationToken: string;
  id: string;
  mail: string;
  name: string;
}

export interface ListedAcceptedOwnerBrief {
  id: string;
  name: string;
  surname: string;
  nip: string;
}

export interface PhoneNumberActivationCodeSent {
  activationCodeSent: boolean;
}

export interface SuccessfulLoginStatusCount {
  successfulLoginAttemptsCount: number;
}

export interface DataCompletenessStatus {
  dataCompleted: boolean;
}

export interface EmployeeListData {
  id: string;
  name: string;
  surname: string;
  ownerStatus: OwnerStatus;
  mail: string;
  phoneNumber: string;
  lastSuccessfulLoginTime: Date | null;
  timeOfRegistration: Date;
  isUserActivated: boolean;
}

export interface EmployeeDataWithPlaces {
  id: string;
  name: string;
  surname: string;
  mail: string;
  phoneNumber: string | null;
  places: PlacePrivilegesShortData[];
}

export interface EmployeeDataWithPrivileges extends EmployeeListData {
  privileges: PlacePrivilege[];
}

export interface WorkerReq {
  mail: string;
  name: string;
  surname: string;
  phoneNumber?: string | null;
  privileges: PlacePrivilege[];
}

export interface UpdateAccountDeviceIdReq {
  fcmDeviceId: string;
}

export enum RegistrationType {
  BASIC = 'BASIC',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}

export interface UpdateAccountDataReq {
  name: string;
  surname: string;
  avatarId?: string | null;
  marketingAgreementAccepted?: boolean;
  birthDate: Date;
  gender?: Gender;
  sourceComment?: string | null;
}

export interface UpdateAccountStartupDataReq extends UpdateAccountDataReq {
  termsAccepted: boolean;
  privacyPolicyAccepted: boolean;
}

export interface AccountActiveOrderNumber {
  activeOrderNumber: number;
}

export interface AccountActivationResult extends OperationStatus {
  role: Role;
}

export type PasswordResetResult = AccountActivationResult;

export interface OwnerPlanPrice {
  planId: string;
  price: number;
}

export interface UpdateOwnerPlansPricesReq {
  prices: OwnerPlanPrice[];
}
