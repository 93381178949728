import {
  I18nEntry,
  ListedPlaceBrief,
  PlaceEntity,
  PlaceSlotEntity,
  UserEntity,
} from 'types';

export interface ReservationPlaceStoredData {
  id: string;
  name: I18nEntry;
}

export interface ReservationEntity {
  id: string;
  reservationId: string;
  place: PlaceEntity;
  placeSnapshot: ReservationPlaceStoredData;
  placeSlot: PlaceSlotEntity;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  from: Date;
  to: Date;
  numOfPeople: number;
  status: ReservationStatus;
  bookedBy: BookedBy;
  user: UserEntity | null;
  createdAt: Date;
  sendReminderAfter: Date | null;
  reminderSentAt: Date | null;
}

export interface ReservationRes {
  id: string;
  reservationId: string;
  placeSlot: PlaceSlotEntity;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  from: Date;
  to: Date;
  numOfPeople: number;
  bookedBy: BookedBy;
  status: ReservationStatus;
}

export enum ReservationStatus {
  REQUESTED = 'REQUESTED',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  VERIFICATION_NECESSARY = 'VERIFICATION_NECESSARY',
}

export const AllReservationStatuses: ReservationStatus[] = [
  ReservationStatus.CONFIRMED,
  ReservationStatus.REQUESTED,
  ReservationStatus.REJECTED,
  ReservationStatus.VERIFICATION_NECESSARY,
];

export interface ReservationMobileAppData {
  id: string;
  placeSlot: { id: string; name: string };
  from: Date;
  numOfPeople: number;
  status: ReservationStatus;
  place: ListedPlaceBrief;
  requestedAt: Date;
}

export enum BookedBy {
  REGISTERED_USER = 'REGISTERED_USER',
  EXTERNAL_CHANNEL = 'EXTERNAL_CHANNEL',
}

export interface PlaceSlotReq {
  id?: string;
  slotNumber: string;
  availableSlots: number;
}

export interface PlaceSlotRes {
  id: string;
  slotNumber: string;
  availableSlots: number;
}

export interface CreatePlaceSlotReq {
  averageReservationTime: number;
  slots?: PlaceSlotReq[] | null;
}

export interface CreatePlaceSlotRes {
  averageReservationTime: number;
  slots: PlaceSlotRes[];
}

export interface CreateReservationReq {
  from: Date;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  slotId: string;
  numOfPeople: number;
}

export interface ReservationAvailableTablesClass {
  id: string;
  name: string;
}

export interface ListedReservationMobileData {
  place: ListedPlaceBrief;
  from: Date;
  requestedAt: Date;
  placeSlot: {
    id: string;
    name: string;
  };
  numOfPeople: number;
  id: string;
  friendlyName: string;
  status: ReservationStatus;
}

export interface MobileAppAccountReservations {
  planned: ListedReservationMobileData[];
  historic: ListedReservationMobileData[];
}

export enum UserReservationList {
  PER_ACCOUNT = 'PER_ACCOUNT',
  PER_PLACE = 'PER_PLACE',
}
