import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { store } from '../config/store';

interface FileData {
  id: string;
  src: string;
}

interface ActionPayload extends FileData {
  name: string;
}

export interface FileState {
  fileData: {
    [name: string]: FileData | null;
  };
}

const fileSlice = createSlice({
  name: 'appModal',
  initialState: {} as FileState,
  reducers: {
    addFile: (state, action: PayloadAction<ActionPayload>) => {
      if (!state.fileData) state.fileData = {};
      state.fileData[action.payload.name] = { id: action.payload.id, src: action.payload.src };
    },
    removeFile: (state, action: PayloadAction<string>) => {
      state.fileData[action.payload] = null;
    },
    clearFileData: (state) => {
      state.fileData = {};
    },
  },
});

export const { addFile, clearFileData, removeFile } = fileSlice.actions;

export const isFile = (name: string): boolean => !!store.getState()?.file?.fileData?.[name]?.id;

export default fileSlice.reducer;
