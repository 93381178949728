export enum CommunicationReceivers {
  ALL_OWNERS = 'ALL_OWNERS',
  OWNERS_OF_SPECIFIED_CITY = 'OWNERS_OF_SPECIFIED_CITY',
  OWNERS_OF_SPECIFIED_PLACE_TYPE = 'OWNERS_OF_SPECIFIED_PLACE_TYPE',
  OWNERS_OF_SPECIFIED_SUBSCRIPTION_TYPE = 'OWNERS_OF_SPECIFIED_SUBSCRIPTION_TYPE',
  END_USERS_ALL = 'END_USERS_ALL',
  END_USERS_OF_SPECIFIED_CITY = 'END_USERS_OF_SPECIFIED_CITY',
  END_USERS_WITH_LAST_ORDER_IN_SPECIFIED_TIME = 'END_USERS_WITH_LAST_ORDER_IN_SPECIFIED_TIME',
  END_USERS_VERY_ACTIVE_LAST_MONTH = 'END_USERS_VERY_ACTIVE_LAST_MONTH', // Min. 3 orders in last month
}
