import { PlanAssignmentEntity } from './plan-assignment';
import { PlanEntity } from './plan';
import { PlaceEntity } from '../place';
import { I18nEntry } from '../i18n';

export interface PlanRequestPlace {
  id: string;
  nip: string;
  name: I18nEntry;
}

export interface PlanRequestPlan {
  id: string;
  name: string;
}

export interface PlanRequestEntity {
  id: string;
  plan: PlanEntity;
  price: number | null;
  place: PlaceEntity;
  planAssignment: PlanAssignmentEntity;
  rejectedAt?: Date | null;
  confirmedAt?: Date | null;
}

export interface PlanRequest {
  id: string;
  plan: PlanRequestPlan;
}

export interface PlanRequestResponse {
  planRequest: PlanRequest;
  place: PlanRequestPlace;
  planAssignment: PlanRequest;
}

export enum PlanRequestStatus {
  CONFIRM = 'CONFIRM',
  REJECT = 'REJECT',
}

export interface PlacePlanRequestResponse {
  plan: PlanEntity | null;
  isRequest: boolean;
}
