export enum OwnerStatus {
  Blocked = 'BLOCKED',
  Waiting = 'WAITING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export const ValidOwnerStatusChange: Record<OwnerStatus, OwnerStatus[]> = {
  [OwnerStatus.Waiting]: [OwnerStatus.Accepted, OwnerStatus.Rejected],
  [OwnerStatus.Rejected]: [OwnerStatus.Accepted],
  [OwnerStatus.Accepted]: [OwnerStatus.Blocked],
  [OwnerStatus.Blocked]: [OwnerStatus.Accepted],
};
