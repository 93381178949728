import { PlacePrivilege, Role, UserRes } from 'app-types';
import { useSelector } from 'react-redux';
import { history } from '../App';
import { RootState } from '../reducers';

interface UseUsers {
  isAdmin: () => boolean;
  hasAccess: (toCheck: UserRes[keyof UserRes], parameter: keyof UserRes) => boolean;
  getPrivileges: (placeId: string) => PlacePrivilege[];
  hasPrivilege: (toCheck: PlacePrivilege, placeId: string) => boolean;
  redirectAfterLogging: (providedUser?: UserRes) => void;
}

export const useUsers = (): UseUsers => {
  const user = useSelector((state: RootState) => state.user?.details);

  const hasAccess = (toCheck: UserRes[keyof UserRes], parameter: keyof UserRes) => {
    if (!user) return false;
    return toCheck === user[parameter];
  };

  const getPrivileges = (placeId: string): PlacePrivilege[] => {
    if (!hasAccess(Role.EMPLOYEE, 'role')) return [];
    return user?.placePrivileges?.find((place) => place.place.id === placeId).privileges;
  };

  const hasPrivilege = (toCheck: PlacePrivilege, placeId: string) =>
    !hasAccess(Role.EMPLOYEE, 'role') || getPrivileges(placeId).includes(toCheck);

  const isAdmin = () =>
    hasAccess(Role.SUPER_ADMINISTRATOR, 'role') || hasAccess(Role.ADMINISTRATOR, 'role');

  const redirectAfterLogging = (providedUser?: UserRes) => {
    if (!providedUser) return;
    if (providedUser.role === Role.USER) {
      history.push('/dashboard/user');
    } else if (user && (!user?.termsAccepted || !user?.privacyPolicyAccepted)) {
      history.push('/rules-confirmation');
    } else if (providedUser?.needsPhoneNumberActivation) {
      history.push('/confirm-phone-number');
    } else if (isAdmin()) {
      history.push('/dashboard/admin');
    } else if (history.location.pathname !== '/add-object') history.push('/choose-object');
  };

  return {
    isAdmin,
    hasAccess,
    getPrivileges,
    hasPrivilege,
    redirectAfterLogging,
  };
};
