import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DynamicField,
  MailTemplateEntity,
  SelectOption,
  RedirectionTargets,
  RedirectionTypeRes,
} from 'app-types';

interface CurrentPushRedirectionType {
  placeId: string;
  objectName: string;
  target: RedirectionTargets;
}

export interface InsertPushType {
  dynamicFields: DynamicField[];
  staticContent: string;
  title: string;
  redirection: CurrentPushRedirectionType | RedirectionTypeRes | null;
}

export interface MailingState {
  dynamicFields: DynamicField[];
  content: string;
  title: string;
  emailTemplate: MailTemplateEntity | null;
  currentReceiversDetailsOptions: SelectOption[];
  currentPushRedirection: CurrentPushRedirectionType | null | RedirectionTypeRes;
}

const initialState: MailingState = {
  dynamicFields: [],
  content: '',
  title: '',
  emailTemplate: null,
  currentReceiversDetailsOptions: [{ label: '', value: '' }],
  currentPushRedirection: null,
};

const mailingSlice = createSlice({
  name: 'appModal',
  initialState,
  reducers: {
    setCurrentReceiversDetailsOptions: (state, action: PayloadAction<SelectOption[]>) => {
      state.currentReceiversDetailsOptions = action.payload;
    },
    setNotificationTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    clearMailingState: (state) => {
      state = initialState;
    },
    setEmailTemplate: (state, action: PayloadAction<MailTemplateEntity>) => {
      state.emailTemplate = action.payload;
    },
    insertPushTemplate: (state, action: PayloadAction<InsertPushType>) => {
      state.dynamicFields = action.payload.dynamicFields;
      state.content = action.payload.staticContent;
      state.title = action.payload.title;
      state.currentPushRedirection = action.payload.redirection;
    },
  },
});

export const {
  setNotificationTitle,
  clearMailingState,
  setEmailTemplate,
  insertPushTemplate,
  setCurrentReceiversDetailsOptions,
} = mailingSlice.actions;

export default mailingSlice.reducer;
