import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { store } from '../config/store';

export interface MultiplePhotoPayload {
  id: string;
  src: string;
  imageData: ImageData | null;
  mimeType: string;
}

export interface SetPhotos {
  fieldName: string;
  isMulti?: boolean;
  photos: MultiplePhotoPayload[];
}

interface InitialState {
  details: {
    [key: string]: MultiplePhotoPayload[];
  };
  loading: boolean;
}

export const multiplePhotoSlice = createSlice({
  name: 'multiplePhotoSlice',
  initialState: {} as InitialState,
  reducers: {
    setPhotos: (state, action: PayloadAction<SetPhotos>) => {
      if (state.details?.[action.payload.fieldName]) {
        if (action.payload?.isMulti) {
          state.details[action.payload.fieldName] = [
            ...state.details[action.payload.fieldName],
            ...action.payload.photos,
          ];
        } else {
          state.details[action.payload.fieldName] = action.payload.photos;
        }
      } else {
        state.details = {
          ...state.details,
          [action.payload.fieldName]: [...action.payload.photos],
        };
      }
    },
    removePhoto: (state, action: PayloadAction<{ id: string; fieldName: string }>) => {
      if (state.details?.[action.payload.fieldName]) {
        state.details[action.payload.fieldName] = state.details?.[action.payload.fieldName].filter(
          (photo) => photo.id !== action.payload.id,
        );
      }
    },
    clearAllPhotos: (state) => {
      state.details = {};
    },
  },
});

export const isPhoto = (photoName: string): boolean =>
  !!store.getState()?.photos?.details?.[photoName]?.[0];

export const { setPhotos, removePhoto, clearAllPhotos } = multiplePhotoSlice.actions;

export default multiplePhotoSlice.reducer;
