export enum MenuImportRecordType {
  Category = 'CATEGORY',
  Product = 'PRODUCT',
  AddonsCategory = 'ADDONS_CATEGORY',
  Addon = 'ADDON',
}

export enum MenuImportStatus {
  Ok,
  OkWithWarnings,
  FatalError,
}

export interface MenuImportLogEntry {
  csvLine: number | null;
  type: MenuImportStatus;
  info: string;
}

export interface MenuImportRes {
  status: MenuImportStatus;
  log: MenuImportLogEntry[];
}
