import { FC } from 'react';
import { Spinner, Center } from '@chakra-ui/react';

interface AppSpinnerInterface {
  fullScreen?: boolean;
}

export const AppSpinner: FC<AppSpinnerInterface> = ({ fullScreen }) => (
  <Center width={fullScreen ? '100vw' : '100%'} height={fullScreen ? '100vh' : '100%'}>
    <Spinner size="xl" color="main" />
  </Center>
);
