import { createSlice } from '@reduxjs/toolkit';

export interface MenuState {
  isOpened: boolean;
}

const initialState: MenuState = {
  isOpened: false,
};

const appModalSlice = createSlice({
  name: 'appModal',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.isOpened = !state.isOpened;
    },
  },
});

export const { toggleMenu } = appModalSlice.actions;

export default appModalSlice.reducer;
