import { combineReducers } from '@reduxjs/toolkit';
import appStatusReducer from '../slices/appStatusSlice';
import appModalReducer from '../slices/appModalSlice';
import langReducer from '../slices/langSlice';
import userReducer from '../slices/userSlice';
import headerReducer from '../slices/headerSlice';
import multiplePhotoReducer from '../slices/photosSlice';
import menuReducer from '../slices/menuSlice';
import orderReducer from '../slices/ordersSlice';
import reservationReducer from '../slices/reservationSlice';
import mailingSlice from '../slices/mailingSlice';
import fileReducer from '../slices/fileSlice';

const rootReducer = combineReducers({
  appStatus: appStatusReducer,
  appModal: appModalReducer,
  lang: langReducer,
  user: userReducer,
  header: headerReducer,
  photos: multiplePhotoReducer,
  file: fileReducer,
  menu: menuReducer,
  orders: orderReducer,
  reservations: reservationReducer,
  mailing: mailingSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
