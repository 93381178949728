import { Options } from 'flatpickr/dist/types/options';
import { addMinutes } from 'date-fns';

export const defaultDatePickerOptions: Options = {
  allowInput: false,
  mode: 'single',
  enableTime: true,
  minDate: addMinutes(new Date(), 30),
};

export const headerSize = '150px';
export const unauthorizedMargins = '205px';
export const mobileHeaderSize = '80px';
export const boxShadow = '0 0 2rem #0000000F';
export const borderRadius = '1.6rem';
export const defaultGap = 2;
export const defaultExtendedGap = 5;
export const defaultPadding = 2;
export const defaultDelay = 300;
export const defaultModalWidth = '512px';
export const mapConfig = {
  geocodingApiKey: 'AIzaSyC39GqBTTq6rt5gI3D6zS02Zt6FMGq_chA',
  defaultZoom: 17,
  defaultPosition: { lat: 50.049683, lng: 19.944544 },
  defaultOptions: {
    disableDefaultUI: true,
    gestureHandling: 'none',
    draggableCursor: 'auto',
    clickableIcons: false,
    keyboardShortcuts: false,
  },
};
