import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { css } from '@emotion/css';
import { css as serializedStyles } from '@emotion/react';
import { CSSProperties } from 'react';
import { boxShadow, borderRadius } from './defaultConfigProps';

const photoMainVariant = (width: string, height: string, additionalCSS?: CSSProperties) => ({
  display: 'block',
  textAlign: 'center',
  margin: 0,
  cursor: 'pointer',
  width: '100%',
  maxWidth: width,
  aspectRatio: `${width} / ${height}`,
  fontSize: '1.3rem',
  position: 'relative',
  color: 'adminMain',
  border: '2px dashed',
  borderColor: 'inactive',
  backgroundSize: 'cover',
  div: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    flexDirection: 'column',
  },
  ...additionalCSS,
});

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '68em',
  xl: '80em',
  '2xl': '96em',
});

const sizes = {
  xs: '1.2rem',
  sm: '1.4rem',
  md: '1.55rem',
  lg: '1.8rem',
  xl: '2rem',
  '2xl': '2.4rem',
  '3xl': '3rem',
  '4xl': '3.6rem',
  '5xl': '4.8rem',
  '6xl': '6rem',
  '7xl': '7.2rem',
  '8xl': '9.6rem',
  '9xl': '12.8rem',
  0.5: '0.2rem',
  1: '0.4rem',
  1.5: '0.6rem',
  2: '0.8rem',
  2.5: '1rem',
  3: '1.2rem',
  3.5: '1.4rem',
  4: '1.6rem',
  5: '2rem',
  6: '2.4rem',
  7: '2.8rem',
  8: '3.2rem',
  9: '3.6rem',
  10: '4.0rem',
  12: '4.8rem',
  14: '5.6rem',
  16: '6.4rem',
  20: '8rem',
  24: '9.6rem',
  28: '11.2rem',
  32: '12.8rem',
  36: '14.4rem',
  40: '16rem',
  44: '17.6rem',
  48: '19.2rem',
  52: '20.8rem',
  56: '22.4rem',
  60: '24rem',
  64: '25.6rem',
  72: '28.8rem',
  80: '32rem',
  96: '38.4rem',
};

const components = {
  Button: {
    baseStyle: {
      fontWeight: 'normal',
      borderRadius: '1rem',
      _hover: {
        opacity: '0.8',
        _disabled: {
          opacity: '0.2',
        },
      },
    },
    variants: {
      ghost: () => ({
        background: 'inherit',
        color: 'light',
        _hover: {
          background: 'inherit',
          _disabled: {
            background: 'inherit',
          },
        },
      }),
      main: () => ({
        bg: 'main',
        p: '2.75rem',
        minWidth: '115px',
        color: 'light',
        _hover: {
          bg: 'main',
          _disabled: {
            background: 'main',
            _disabled: {
              background: 'main',
            },
          },
        },
      }),
      mainNoHover: () => ({
        bg: 'main',
        color: 'light',
        p: '2.75rem',
        minWidth: '115px',
        cursor: 'default',
        _hover: {
          opacity: 1,
        },
        _focus: {
          boxShadow: 'none',
        },
      }),
      simple: () => ({
        background: 'adminMain',
        color: 'light',
        p: '2.75rem',
        minWidth: '115px',
        _hover: {
          background: 'adminMain',
          _disabled: {
            background: 'adminMain',
          },
        },
      }),
      adminMain: () => ({
        background: 'adminMain',
        p: '2.75rem',
        minWidth: '115px',
        color: 'light',
        _hover: {
          background: 'adminMain',
          _disabled: {
            background: 'adminMain',
          },
        },
      }),
      backgroundApp: () => ({
        background: 'backgroundApp',
        p: '2.75rem',
        minWidth: '115px',
        color: 'light',
        _hover: {
          background: 'backgroundApp',
          _disabled: {
            background: 'backgroundApp',
          },
        },
      }),
      positive: () => ({
        background: 'accepted',
        p: '2.75rem',
        minWidth: '115px',
        color: 'light',
        _hover: {
          background: 'accepted',
          _disabled: {
            background: 'accepted',
          },
        },
      }),
      blank: () => ({
        background: 'light',
        boxShadow: '0 1px 1px 1px input',
        _hover: {
          background: 'light',
          _disabled: {
            background: 'light',
          },
        },
      }),
      reverseMain: () => ({
        background: 'mainBg',
        color: 'main',
        p: '2.75rem',
        minWidth: '115px',
        _hover: {
          background: 'mainBg',
          _disabled: {
            background: 'mainBg',
          },
        },
      }),
      textOnly: () => ({
        background: 'inherit',
        color: 'dark',
        p: '2.75rem',
        minWidth: '115px',
        _hover: {
          background: 'inherit',
          _disabled: {
            background: 'inherit',
          },
        },
      }),
      back: () => ({
        minWidth: '24px',
        height: '24px',
        padding: 1,
        marginRight: 3,
        background: 'light',
        boxShadow: '0 1px 1px 1px input',
        _hover: {
          background: 'light',
          _disabled: {
            background: 'light',
          },
        },
      }),
      gotoPage: () => ({
        padding: [0, null, null, 6],
        color: 'input',
        background: 'light',
        width: ['5px', null, null, '50px'],
        _hover: {
          background: 'light',
          _disabled: {
            background: 'light',
          },
        },
      }),
      sideButton: () => ({
        fontSize: '1.2rem',
        fontWeight: 'normal',
        background: 'adminBg',
        color: 'adminMain',
        marginBottom: 4,
        _hover: {
          background: 'adminBg',
          _disabled: {
            background: 'adminBg',
          },
        },
      }),
      hamburger: () => ({
        color: 'main',
        background: 'transparent',
        _hover: {
          background: 'transparent',
          _disabled: {
            background: 'transparent',
          },
        },
      }),
      redirectButton: () => ({
        background: 'transparent',
        textDecoration: 'underline',
        maxWidth: '48rem',
        _hover: {
          background: 'transparent',
          _disabled: {
            background: 'transparent',
          },
        },
      }),
      reject: () => ({
        background: 'light',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '10px',
        height: '55px',
        borderColor: 'rejectBorder',
        _hover: {
          background: 'light',
          _disabled: {
            background: 'light',
          },
        },
      }),
      borderBottomTextOnly: () => ({
        background: 'inherit',
        color: 'input',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: 'border',
        width: '100%',
        justifyContent: 'left',
        borderRadius: 0,
        _hover: {
          background: 'inherit',
          _disabled: {
            background: 'inherit',
          },
        },
      }),
      yellowBg: () => ({
        bg: 'lightYellow',
        color: 'input',
        _hover: {
          bg: 'lightYellow',
          _disabled: {
            background: 'lightYellow',
            _disabled: {
              background: 'lightYellow',
            },
          },
        },
      }),
    },
  },
  Text: {
    variants: {
      morePages: () => ({
        maxWidth: '100%',
        px: [1, null, null, 6],
      }),
    },
  },
  Container: {
    variants: {
      column: () => ({
        display: 'flex',
        flexDirection: 'column',
        a: {
          mb: '1rem',
        },
      }),
      centeredButtons: () => ({
        height: '55px',
        background: 'disabled',
        px: '0.5rem',
        margin: ['1.2rem auto', null, null, '0 2.4rem'],
        width: 'auto',
        display: 'inline-flex',
        borderRadius: '0.6rem',
        button: {
          height: '45px',
        },
      }),
      dashboardBox: () => ({
        background: '#FFFFFF',
        borderRadius: '1.5rem',
        mb: 8,
        padding: ['2.5rem', null, null, '5rem'],
      }),
      tableBox: () => ({
        background: '#FFFFFF',
        borderRadius: '1.5rem',
        paddingTop: 5,
        scrollbarWidth: 'none',
      }),
      unauthorized: () => ({
        background: 'light',
        spacing: 3,
        padding: 8,
        marginBottom: '5rem',
        boxShadow,
        borderRadius,
        minWidth: ['100px', '500px'],
      }),
      navBarBottom: () => ({
        paddingTop: 4,
        paddingLeft: 4,
        margin: 0,
      }),
      DefaultUploadFile: () => ({
        display: 'block',
        textAlign: 'center',
        margin: 0,
        cursor: 'pointer',
        width: '100%',
        maxWidth: '320px',
        aspectRatio: '2',
        fontSize: '1.3rem',
        position: 'relative',
        color: 'input',
        border: '2px dashed',
        borderColor: 'border',
        backgroundSize: 'cover',
        div: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          flexDirection: 'column',
        },
      }),
      agreementUploadFile: () => ({
        display: 'block',
        textAlign: 'center',
        cursor: 'pointer',
        border: '2px dashed',
        borderColor: 'border',
        padding: 12,
        borderRadius: '0.5rem',
        backgroundSize: 'cover',
        div: {
          flexDirection: 'column',
          padding: 2,
        },
      }),
      AvatarUploadFile: photoMainVariant('250', '250', { borderRadius: '100%' }),
      UploadPhoto: photoMainVariant('520', '250'),
      BannerPhoto: photoMainVariant('1030', '300'),
    },
  },
  Input: {
    baseStyle: {
      field: {
        padding: '1.6rem',
        borderRadius,
      },
    },
    variants: {
      filterButton: () => ({
        field: {
          background: 'light',
          height: '55px',
          borderWidth: '1px',
          borderColor: 'border',
        },
      }),
    },
  },
};

const colors = {
  adminMain: '#2680EB',
  adminBg: '#E3EFFD',
  main: '#E6006E',
  mainBg: '#FDE5F0',
  secondary: '#FAB400',
  background: '#F9F9F9',
  light: '#FFFFFF',
  dark: '#000000',
  input: '#1D293F',
  backgroundApp: '#0000001A',
  accepted: '#2BC155',
  blocked: '#E1576E',
  delete: '#FF4E4E',
  deleteHover: '#FF2C2C',
  waiting: '#648FB9',
  disabled: '#E8E8E8',
  inactive: '#BDC9D5',
  borderLight: '#0000000F',
  categoryBg: '#EFF6FE',
  border: '#CBD5E0',
  rejectBorder: '#E2E2E2',
  oddBackground: '#F8F8F8',
  lightYellow: '#FFF5DB',
};

const statusBackgroundColors = {
  accepted: '#E1F6E7',
  rejected: '#F7F7F7',
  blocked: '#FDE4E8',
  waiting: '#E3EAF4',
};

const styles = {
  global: {
    '::-webkit-scrollbar': {
      width: '8px',
      borderRadius: '8px',
      height: '8px',
      backgroundColor: 'rgba(200, 200, 200, .5)',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(57,57,57, .6)',
      borderRadius: '8px',
    },
    html: {
      fontSize: '62.5%',
    },
    body: {
      fontSize: '1.6rem',
      fontFamily: 'Poppins, sans-serif',
    },
    a: {
      _hover: {
        textDecoration: 'none !important',
      },
    },
    'div.chakra-alert__title': {
      lineHeight: '2.3rem',
    },
    '@media (max-width: 80em)': {
      '#chakra-toast-manager-top': {
        bottom: '0',
        top: 'initial !important',
      },
    },
    '.flatpickr-calendar.inline': {
      width: '100%',
      '.flatpickr-innerContainer,.flatpickr-rContainer,.flatpickr-weekdays,.flatpickr-days,.dayContainer':
        {
          width: '100%',
          maxWidth: '100%',
        },
    },
  },
};

export const theme = extendTheme({
  colors,
  statusBackgroundColors,
  components,
  styles,
  fonts: {
    body: 'Poppins, sans-serif',
  },
  fontSizes: sizes,
  sizes,
  space: sizes,
  breakpoints,
  shadows: { outline: 'none' },
});

export const flatpickrInput = css`
  border: 1px solid #f9f9f9;
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
`;

export const disabledInput = css`
  opacity: 0.4;
  div {
    background: white;
  }
`;

export const flatpickrInputWithButton = css`
  position: absolute;
  height: 60px;
  width: 0;
  opacity: 0;
  pointer-events: none;
`;

export const inlineInput = serializedStyles`
  display: flex;
  flex-wrap: wrap;
  label:nth-of-type(1) {
    order: 2;
    margin: 0.5rem;
  }
  div {
    order: 3;
    flex: 1 0 100%;
  }
`;
