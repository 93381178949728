export enum GlobalPrivilege {
  ANONYMIZE_ACCOUNT = 'ANONYMIZE_ACCOUNT',
  MANAGE_GLOBAL_CATEGORIES = 'MANAGE_GLOBAL_CATEGORIES',
  MODIFY_PLACE_DATA = 'MODIFY_PLACE_DATA',
  MANAGE_PLACE_MENU = 'MANAGE_PLACE_MENU',
  MANAGE_PLACE_EMPLOYEES = 'MANAGE_PLACE_EMPLOYEES',
  MANAGE_PLACE_ORDERS = 'MANAGE_PLACE_ORDERS',
  MANAGE_PLACE_ORDERS_HISTORY = 'MANAGE_PLACE_ORDERS_HISTORY',
  MANAGE_PLACE_REPORTS = 'MANAGE_PLACE_REPORTS',
  MANAGE_RESERVATION = 'MANAGE_RESERVATION',
  MANAGE_PLACE_PROMOTION = 'MANAGE_PLACE_PROMOTION',
  MANAGE_COMMUNICATION = 'MANAGE_COMMUNICATION',
  MANAGE_PLACE_BANNER_SET = 'MANAGE_PLACE_BANNER_SET',
}
