import {
  ProductTemplateEntity,
  Currency,
  FileEntity,
  GlobalCategoryEntity,
  MobileAppProductOptionGroup,
  OptionGroupEntity,
  PlaceCategoryEntity,
  I18nEntry,
  I18nArray,
} from 'types';

export enum ProductTag {
  Vegetarian = 'Vegetarian',
  Meat = 'Meat',
  Glutenfree = 'Glutenfree',
  Vegan = 'Vegan',
}

export enum MenuType {
  Daily = 'Daily',
  Night = 'Night',
  DailyAndNight = 'DailyAndNight',
}

export enum UnitWeightType {
  Gram = 'Gram',
  Millilitre = 'Millilitre',
}

export interface SuggestedProductBrief {
  id: string;
  name: I18nEntry;
}

export interface ProductEntity {
  id: string;
  name: I18nEntry;
  description: I18nEntry;
  file: FileEntity | null;
  category: GlobalCategoryEntity;
  productCategories: ProductTag[];
  price: number;
  promotionalPrice: number | null;
  addedAt: Date;
  ingredients: I18nArray;
  allergens: I18nArray;
  suggestedProducts: SuggestedProductBrief[];
  kilocalories: number;
  proteins: number;
  fats: number;
  carbohydrates: number;
  sugars: number;
  optionGroup: OptionGroupEntity[];
  menuType: MenuType;
  averagePreparationTime: number | null;
  isAlcohol: boolean;
  sourceTemplate: ProductTemplateEntity | null;
  unitWeightType: UnitWeightType | null;
  unitWeightValue: number;
}

export interface ProductDataMobileApp {
  id: string;
  name: string;
  price: number;
  promotionalPrice: number | null;
  description: string | null;
  iconSrc: string | null;
  tags: ProductTag[];
  ingredients: string[];
  allergens: string[];
  kilocalories: number;
  weight: number;
  proteins: number | null;
  fats: number | null;
  carbohydrates: number | null;
  sugars: number | null;
  menuType: MenuType;
  optionGroups: MobileAppProductOptionGroup[];
  moneyCode: Currency;
  isAlcohol: boolean;
}

export interface ProductDataCard {
  id: string;
  name: I18nEntry;
  price: number;
  promotionalPrice: number | null;
  description: I18nEntry | null;
  iconSrc: string | null;
  tags: ProductTag[];
  isAlcohol: boolean;
}

export interface ProductReq {
  name: I18nEntry;
  description: I18nEntry;
  price: number;
  category: PlaceCategoryEntity;
  productCategories: string[];
  promotionalPrice?: number | null;
  fileId?: string | null;
  optionsGroup: OptionGroupReq[];
  ingredients: I18nArray;
  allergens: I18nArray;
  suggestedProductIds: string[];
  kilocalories?: number;
  proteins?: number | null;
  fats?: number | null;
  carbohydrates?: number | null;
  sugars?: number | null;
  menuType: MenuType;
  averagePreparationTime: number;
  isAlcohol: boolean;
  sourceTemplateId?: string | null;
  unitWeightType?: string | null;
  unitWeightValue?: number | null;
}

export interface OptionGroupReq {
  title: I18nEntry;
  options: OptionReq[];
  minimalSelectedCount: number;
  maximalSelectedCount: number;
}

export interface OptionReq {
  name: I18nEntry;
  price?: number;
}

export interface ChangeMenuTypeReq {
  menuType: MenuType;
}
