import { ComponentType, FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Role } from 'app-types';
import { useUsers } from '../../../hooks/useUsers';
import { RootState } from '../../../reducers';

interface Props {
  component?: ComponentType<RouteComponentProps> | ComponentType;
  exact?: boolean;
  path?: string;
  render?: (props: RouteComponentProps) => ReactNode;
}

export const RouteAuthorized: FC<Props> = ({ exact, path, component, render }) => {
  const user = useSelector((state: RootState) => state.user?.details);
  const { isAdmin } = useUsers();

  if (user?.needsPhoneNumberActivation) {
    return <Redirect to="/confirm-phone-number" />;
  } else if (user && (!user?.termsAccepted || !user?.privacyPolicyAccepted)) {
    return <Redirect to="/rules-confirmation" />;
  } else if (isAdmin() || user?.role === Role.OWNER || user?.role === Role.EMPLOYEE) {
    return <Route exact={exact} path={path} component={component} render={render} />;
  } else {
    return <Route render={() => <Redirect to="/" />} />;
  }
};
