export enum ApiErrorCode {
  OtherError,
  UserNotFound,
  NoSuchObjectWithThisId,
  InvalidLang,
  ObjectNotUnique,
  FormValidationError,
  ThisCountryIsNotSupported,
  NipTaken,
  MailTaken,
  PhoneNumberTaken,
  InvalidCredentials,
  PhoneNumberAlreadyActivated,
  PhoneNumberNotSet,
  PhoneNumberActivationCodeInvalid,
  AccountIsInactive,
  AccountIsActive,
  InvalidToken,
  MissingTimeRange,
  InvalidReverseTimeRange,
  WorkingHoursExceptionAlreadyExistsForSelectedDate,
  PlaceIsAlreadyAccepted,
  UserIsEmployeeOfSelectedPlace,
  AccountRoleNotAllowed,
  UserIsEmployeeOfPlaceOfOtherOwner,
  UnacceptablePhoneNumberProvided,
  CannotDeleteUtilisedFile,
  MissingFileOwnership,
  MissingLocalizationData,
  LocationOfPlaceOfThisTypeIsRequired,
  PromotionNameTaken,
  StartDateAfterEndDate,
  PromotionCodeOutOfDate,
  PromotionNotExist,
  CreationDateMustBeAfterToday,
  CannotDeleteCategoryWithActiveProducts,
  MissingGlobalCategoryForInheritingPlaceCategory,
  MissingNameOfNewNoninheritedPlaceCategory,
  PlaceCategoryThatInheritsSelectedGlobalCategoryAlreadyExist,
  CannotChangeNameOfInheritedCategory,
  UpdateTimeLimitExceeded,
  CannotDecreaseNumberOfCodes,
  FileNotProvided,
  UnacceptableFile,
  UserCannotPlaceOrder,
  PlaceCannotAcceptOrders,
  ProductNotFound,
  ProductNotAvailableInPlaceMenu,
  ProductOptionDoesNotBelongToSelectedProduct,
  MinimalSelectedOptionCountNotReached,
  MaximalSelectedOptionCountExceeded,
  OrderStatusChangeNotAllowed,
  CannotChangeTypeOfOrder,
  ReportTypeNotExist,
  PlaceNotFound,
  TooManyPeopleForThisSlot,
  SlotIsAlreadyReserved,
  PlaceStatusChangeNotAllowed,
  PlaceLocationChangesAtSelectedDate,
  AccountPasswordIsNotSet,
  AverageReservationTimeIsNotSet,
  ReservationTimeNotOnTimetable,
  WrongReservationTime,
  TooLongValueWithDynamicFields,
  PlaceSlotsAreIncorrect,
  PromotionCannotBeDeleted,
  InvalidMimeType,
  WrongPlaceReservationStatus,
  ReservationsAreCurrentlyPaused,
  InvalidOwnerStatusChange,
  CannotUpdateBlueMediaDataOfPlace,
  InvalidBlueMediaHash,
  CannotGetFormLinkFromBlueMedia,
  EmailIsRequiredButNotGiven,
  NoSalesRules,
  TableNotAvailable,
  TableBookingIsTemporaryDisabled,
  CannotPlaceMoreReservation,
  UserDoesNotHaveCompanyDataSet,
  OperationIsForbidden,
  PromotionalCodeIsUsed,
  MaximalSeatsCountExceeded,
  MaximalNumberOfBannersReached,
  InvalidPercentRange,
  OrderValueTooLowToUseThisPromotion,
  InvalidTokenPasswordReset,
  PromotionalCodeNotFound,
  AccountIsNotAuthorizedToUseCode,
  CurrencyMismatch,
  PlaceIsNotRegisteredInBlueMedia,
  PlaceTypeDoesNotAllowReservation,
  ProductNameIsRequired,
  OptionNameIsRequired,
  OptionGroupTitleIsRequired,
  PlaceIsNotAcceptedByBlueMedia,
  PasswordResetTokenIsInvalid,
  PlaceDoesNotProvideTableBooking,
  PlaceNameIsRequired,
  ProductCannotBeOrderedAtSelectedTime,
  UnknownPlanRequestStatus,
  RequestAlreadyExists,
  PlanNotAllowedForSelectedPlace,
  PlanAlreadyAssigned,
  PlanNotFound,
  DateFromLowerThanAllowedTime,
  DateFromLowerThanCurrentTime,
  UserBlocked,
  DiscountValueOutOfRange,
  ReservationNotConfirmed,
  NightMenuNotCreated,
  PlanAssignmentNotFound,
  UnknownMenuType,
}
