export enum BlueMediaActivityKind {
  NON_ACCOUNTED_ACTIVITY = 'NON_ACCOUNTED_ACTIVITY',
  PROPRIETORSHIP = 'PROPRIETORSHIP',
  CIVIL_PARTNERSHIP = 'CIVIL_PARTNERSHIP',
  LIMITED_LIABILITY_PARTNERSHIP = 'LIMITED_LIABILITY_PARTNERSHIP',
  GENERAL_PARTNERSHIP = 'GENERAL_PARTNERSHIP',
  SP_ZOO = 'SP_ZOO',
  SA = 'SA',
  LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP',
  LIMITED_JOINT_STOCK_PARTNERSHIP = 'LIMITED_JOINT_STOCK_PARTNERSHIP',
  SOCIETY = 'SOCIETY',
  FOUNDATION = 'FOUNDATION',
  COOPERATIVE = 'COOPERATIVE',
  GOVERNMENT = 'GOVERNMENT',
  SELF_GOVERNMENTAL_UNIT = 'SELF_GOVERNMENTAL_UNIT',
  SELF_GOVERNMENTAL_CULTURAL_INSTITUTION = 'SELF_GOVERNMENTAL_CULTURAL_INSTITUTION',
  STATE_OWNED_ENTERPRISE = 'STATE_OWNED_ENTERPRISE',
  STATE_CULTURAL_INSTITUTION = 'STATE_CULTURAL_INSTITUTION',
  PUBLIC_SECTOR_INSTITUTION = 'PUBLIC_SECTOR_INSTITUTION',
  RESEARCH_INSTITUTION = 'RESEARCH_INSTITUTION',
  CHURCH = 'CHURCH',
  FOREIGN = 'FOREIGN',
}
