export enum PlaceType {
  FOOD_TRUCK = 'FOOD_TRUCK',
  RESTAURANT = 'RESTAURANT',
  HOTEL = 'HOTEL',
  PUB_CLUB = 'PUB_CLUB',
}

export const AllPlaceTypes = [
  PlaceType.FOOD_TRUCK,
  PlaceType.HOTEL,
  PlaceType.RESTAURANT,
  PlaceType.PUB_CLUB,
];
