import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReservationRes, ReservationStatus } from 'app-types';
import axios from 'axios';
import { axiosRequestConfiguration } from '../config/axios';
import { ApiPayload } from '../hooks/api/useApi';

export interface ReservationSlice {
  reservations: ReservationRes[];
  isOffline: boolean;
}

const initialState: ReservationSlice = {
  reservations: [],
  isOffline: false,
};

const axiosInstance = axios.create(axiosRequestConfiguration);

export const getActiveReservations = createAsyncThunk(
  'waiting/reservation',
  async (placeId: string) => {
    const reservations = await axiosInstance
      .get<ApiPayload<ReservationRes[]>>(
        `reservation/panel/places/${placeId}/${ReservationStatus.REQUESTED}`,
      )
      .then((res) => res.data.payload);
    return reservations ?? null;
  },
);

const reservationSlice = createSlice({
  name: 'reservationSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActiveReservations.fulfilled, (state, action) => {
      state.isOffline = false;
      if (action.payload) {
        state.reservations = action.payload;
      }
    });
    builder.addCase(getActiveReservations.rejected, (state) => {
      state.isOffline = true;
    });
  },
});

export default reservationSlice.reducer;
