import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserRes } from 'app-types';
import axios from 'axios';
import { handleError } from '../helpers/handleError';
import { axiosRequestConfiguration } from '../config/axios';
import { ApiPayload } from '../hooks/api/useApi';
import { history } from '../App';

export interface UserState {
  details?: UserRes;
  inProgress: boolean;
  isError: boolean;
  logoutInProgress: boolean;
}

const initialState: UserState = {
  inProgress: false,
  isError: false,
  logoutInProgress: false,
};

const axiosInstance = axios.create(axiosRequestConfiguration);

export const getUserDetails = createAsyncThunk('panel/account/data', async (isHidden?: boolean) => {
  const user = await axiosInstance
    .get<ApiPayload<Promise<UserRes>>>('user/panel/account/data')
    .then((res) => res.data.payload)
    .catch((err) => {
      if (!isHidden) handleError(err?.response);
    });
  return user as UserRes;
});

export const logout = createAsyncThunk('auth/logout', async () =>
  axiosInstance
    .post('auth/logout')
    .then(() => history.push('/login'))
    .catch((err) => handleError(err)),
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.details = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state) => {
      state.inProgress = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.inProgress = false;
      state.details = action.payload;
    });
    builder.addCase(getUserDetails.rejected, (state) => {
      state.inProgress = false;
      state.isError = true;
    });
    builder.addCase(logout.pending, (state) => {
      state.logoutInProgress = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.logoutInProgress = false;
      state.details = undefined;
    });
    builder.addCase(logout.rejected, (state) => {
      state.logoutInProgress = false;
    });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
