export enum Language {
  pl = 'pl',
  en = 'en',
  de = 'de',
}

export const LanguageFallback: Language[] = [
  Language.pl,
  Language.en,
  Language.de,
];
