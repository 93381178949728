import { Dispatch } from 'react';
import { setIsOnline } from '../slices/appStatusSlice';
import { store } from '../config/store';

export const setWindowState = (): void => {
  const listenToWindowEvent =
    (name: string, mapEventToAction: () => void) => (dispatch: Dispatch<() => void>) => {
      const handleEvent = () => {
        dispatch(mapEventToAction);
      };
      window.addEventListener(name, handleEvent);
    };

  if (store) {
    store.dispatch(listenToWindowEvent('offline', () => setIsOnline(false)));

    store.dispatch(listenToWindowEvent('online', () => setIsOnline(true)));

    store.dispatch(setIsOnline(window.navigator.onLine));
  }
};
