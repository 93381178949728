export enum PlaceTag {
  PIZZA = 'PIZZA',
  ITALIAN_CUISINE = 'ITALIAN_CUISINE',
  POLISH_CUISINE = 'POLISH_CUISINE',
  SANDWICHES = 'SANDWICHES',
  BURGERS = 'BURGERS',
  BEVERAGES = 'BEVERAGES',
  ASIAN_KITCHEN = 'ASIAN_KITCHEN',
  INDIAN_KITCHEN = 'INDIAN_KITCHEN',
  MEXICAN_CUISINE = 'MEXICAN_CUISINE',
  VEGETARIAN_COOKING = 'VEGETARIAN_COOKING',
  PASTA = 'PASTA',
  DESSERTS = 'DESSERTS',
  COFFEE = 'COFFEE',
  DUMPLINGS = 'DUMPLINGS',
  RESTAURANTS = 'RESTAURANTS',
  HOTELS = 'HOTELS',
  CLUBS = 'CLUBS',
  PUBS = 'PUBS',
  FAST_DELIVERY = 'FAST_DELIVERY',
  SETS = 'SETS',
  ORIENTAL_CUISINE = 'ORIENTAL_CUISINE',
  SUSHI = 'SUSHI',
  LUNCH = 'LUNCH',
  SNACKS = 'SNACKS',
  FOR_CHILDREN = 'FOR_CHILDREN',
  SEAFOOD = 'SEAFOOD',
  HEALTHY = 'HEALTHY',
  HEALTHY_FOOD = 'HEALTHY_FOOD',
  NIGHT_MENU = 'NIGHT_MENU',
  NIGHT = 'NIGHT',
  VEGAN = 'VEGAN',
  SLOW_FOOD = 'SLOW_FOOD',
  EUROPEAN_CUISINE = 'EUROPEAN_CUISINE',
  FUSION = 'FUSION',
  RAMEN = 'RAMEN',
  ROMANTIC = 'ROMANTIC',
  FOR_TWO = 'FOR_TWO',
  ON_A_BUDGET = 'ON_A_BUDGET',
  CHIPS = 'CHIPS',
  BREAKFASTS = 'BREAKFASTS',
  DINNER = 'DINNER',
  WITH_FRIENDS = 'WITH_FRIENDS',
  BUSINESS_MEETING = 'BUSINESS_MEETING',
  ICE_CREAM = 'ICE_CREAM',
  WAFFLES = 'WAFFLES',
  VODKA = 'VODKA',
  COCKTAILS = 'COCKTAILS',
  DRINKS = 'DRINKS',
  BEER = 'BEER',
  CRAFT_BEER = 'CRAFT_BEER',
  ALCOHOL_FREE_DRINKS = 'ALCOHOL_FREE_DRINKS',
  POLISH_ALCOHOL = 'POLISH_ALCOHOL',
  APEROL = 'APEROL',
  WINE = 'WINE',
  EXCLUSIVE_ALCOHOLS = 'EXCLUSIVE_ALCOHOLS',
  EXTRAS = 'EXTRAS',
  SOUPS = 'SOUPS',
  WHISKEY = 'WHISKEY',
}
