import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderRes } from 'app-types';
import axios from 'axios';
import { axiosRequestConfiguration } from '../config/axios';
import { ApiPayload } from '../hooks/api/useApi';

export interface OrdersState {
  activeOrders: OrderRes[];
  ordersToShow: OrderRes[];
  total: number;
  isOffline: boolean;
}

const initialState: OrdersState = {
  activeOrders: [],
  ordersToShow: [],
  isOffline: false,
  total: 0,
};

const axiosInstance = axios.create(axiosRequestConfiguration);

export const getActiveOrders = createAsyncThunk('active/order', async (placeId: string) => {
  const orders = await axiosInstance
    .get<ApiPayload<OrderRes[]>>(`order/panel/places/${placeId}/active-orders`)
    .then((res) => res.data.payload);
  return orders ?? null;
});

const ordersSlice = createSlice({
  name: 'ordersSlice',
  initialState,
  reducers: {
    setCurrentOrders: (state, action) => {
      state.activeOrders = action.payload;
    },
    newOrder: (state, action: PayloadAction<OrderRes>) => {
      state.ordersToShow.push(action.payload);
    },
    removeFromOrdersToShow: (state, action: PayloadAction<{ id: string }>) => state,
    removeOrder: (state, action) => state,
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveOrders.fulfilled, (state, action) => {
      state.isOffline = false;
      if (action.payload) {
        state.activeOrders = action.payload;
      }
    });
    builder.addCase(getActiveOrders.rejected, (state) => {
      state.isOffline = true;
    });
  },
});

export const { newOrder, removeOrder } = ordersSlice.actions;

export default ordersSlice.reducer;
