import { FC } from 'react';
import { css, Global } from '@emotion/react';

export const StyledFlatpickr: FC = () => (
  <Global
    styles={css`
      .flatpickr-input {
        padding: 0.65rem;
        outline-offset: 2px;
        border-radius: 0.375rem;
        border: 1px solid #e2e8f0;
      }

      .today {
        color: #000 !important;
        background: #fff !important;
        transition: all 0.3s ease-in-out;
      }
      span.flatpickr-day.today:not(.selected) {
        border: 1px solid #e1576e;
      }
      .today:hover {
        color: #fff !important;
        background: #ca596c !important;
      }

      .flatpickr-month {
        background: #fff !important;
        color: #e1576e !important;
        margin-bottom: 5px !important;
      }

      .flatpickr-weekdays {
        color: #fff !important;
        background: #e1576e !important;
        font-weight: 100 !important;
      }

      .numInputWrapper {
        color: #e1576e !important;
      }

      .flatpickr-weekdaycontainer {
        color: #fff !important;
      }

      .flatpickr-monthDropdown-months {
        color: #e1576e !important;
      }

      .flatpickr-weekday {
        color: #fff !important;
      }

      .selected {
        background: #e1576e !important;
        border: 1px solid #fff !important;
      }

      .flatpickr-next-month {
        fill: #e1576e !important;
      }

      .flatpickr-prev-month {
        fill: #e1576e !important;
      }

      .flatpickr-monthDropdown-month {
        background: #fff !important;
        border: 1px solid #e1576e !important;
      }
    `}
  />
);
