import { ApiErrorCode } from 'app-types';
import { __ } from './i18n';

const translate = (key: string): string => __(`error.${key}`);

export const getErrorMessage = (error: ApiErrorCode): string => {
  switch (error) {
    case ApiErrorCode.OtherError:
      return translate('occurred');
    case ApiErrorCode.UserNotFound:
      return translate('userNotFound');
    case ApiErrorCode.NoSuchObjectWithThisId:
      return translate('noSuchObjectWithThisId');
    case ApiErrorCode.InvalidLang:
      return translate('invalidLang');
    case ApiErrorCode.ObjectNotUnique:
      return translate('objectNotUnique');
    case ApiErrorCode.FormValidationError:
      return translate('formValidationError');
    case ApiErrorCode.ThisCountryIsNotSupported:
      return translate('thisCountryIsNotSupported');
    case ApiErrorCode.MailTaken:
      return translate('mailTaken');
    case ApiErrorCode.NipTaken:
      return translate('nipTaken');
    case ApiErrorCode.PhoneNumberTaken:
      return translate('phoneNumberTaken');
    case ApiErrorCode.InvalidCredentials:
      return translate('invalidCredentials');
    case ApiErrorCode.PhoneNumberAlreadyActivated:
      return translate('phoneNumberAlreadyActivated');
    case ApiErrorCode.PhoneNumberNotSet:
      return translate('phoneNumberNotSet');
    case ApiErrorCode.PhoneNumberActivationCodeInvalid:
      return translate('phoneNumberActivationCodeInvalid');
    case ApiErrorCode.AccountIsInactive:
      return translate('accountIsInactive');
    case ApiErrorCode.AccountIsActive:
      return translate('accountIsActive');
    case ApiErrorCode.InvalidToken:
      return translate('invalidToken');
    case ApiErrorCode.InvalidTokenPasswordReset:
      return translate('invalidTokenPasswordReset');
    case ApiErrorCode.MissingTimeRange:
      return translate('missingTimeRange');
    case ApiErrorCode.InvalidReverseTimeRange:
      return translate('invalidReverseTimeRange');
    case ApiErrorCode.WorkingHoursExceptionAlreadyExistsForSelectedDate:
      return translate('dateAlreadyExists');
    case ApiErrorCode.PlaceIsAlreadyAccepted:
      return translate('placeIsAlreadyAccepted');
    case ApiErrorCode.UserIsEmployeeOfSelectedPlace:
      return translate('userIsEmployeeOfSelectedPlace');
    case ApiErrorCode.AccountRoleNotAllowed:
      return translate('accountRoleNotAllowed');
    case ApiErrorCode.UserIsEmployeeOfPlaceOfOtherOwner:
      return translate('userIsEmployeeOfPlaceOfOtherOwner');
    case ApiErrorCode.UnacceptablePhoneNumberProvided:
      return translate('unacceptablePhoneNumberProvided');
    case ApiErrorCode.CannotDeleteUtilisedFile:
      return translate('cannotDeleteUtilisedFile');
    case ApiErrorCode.MissingFileOwnership:
      return translate('missingFileOwnership');
    case ApiErrorCode.MissingLocalizationData:
      return translate('missingLocalizationData');
    case ApiErrorCode.LocationOfPlaceOfThisTypeIsRequired:
      return translate('locationOfPlaceOfThisTypeIsRequired');
    case ApiErrorCode.PromotionNameTaken:
      return translate('promotionNameTaken');
    case ApiErrorCode.StartDateAfterEndDate:
      return translate('invalidReverseTimeRange');
    case ApiErrorCode.PromotionCodeOutOfDate:
      return translate('promotionCodeOutOfDate');
    case ApiErrorCode.PromotionNotExist:
      return translate('promotionNotExist');
    case ApiErrorCode.CreationDateMustBeAfterToday:
      return translate('dateMustBeAfterToday');
    case ApiErrorCode.CannotDeleteCategoryWithActiveProducts:
      return translate('cannotDeleteCategoryWithActiveProducts');
    case ApiErrorCode.MissingGlobalCategoryForInheritingPlaceCategory:
      return translate('missingGlobalCategoryForInheritingPlaceCategory');
    case ApiErrorCode.MissingNameOfNewNoninheritedPlaceCategory:
      return translate('missingNameOfNewNonInheritedPlaceCategory');
    case ApiErrorCode.PlaceCategoryThatInheritsSelectedGlobalCategoryAlreadyExist:
      return translate('placeCategoryThatInheritsSelectedGlobalCategoryAlreadyExist');
    case ApiErrorCode.CannotChangeNameOfInheritedCategory:
      return translate('cannotEditThisPromotion');
    case ApiErrorCode.UpdateTimeLimitExceeded:
      return translate('updateTimeLimitExceeded');
    case ApiErrorCode.CannotDecreaseNumberOfCodes:
      return translate('cannotDecreaseNumberOfCodes');
    case ApiErrorCode.FileNotProvided:
      return translate('fileNotProvided');
    case ApiErrorCode.UnacceptableFile:
      return translate('unacceptableFile');
    case ApiErrorCode.UserCannotPlaceOrder:
      return translate('userCannotPlaceOrder');
    case ApiErrorCode.PlaceCannotAcceptOrders:
      return translate('placeCannotAcceptOrders');
    case ApiErrorCode.ProductNotFound:
      return translate('productNotFound');
    case ApiErrorCode.ProductNotAvailableInPlaceMenu:
      return translate('productNotAvailableInPlaceMenu');
    case ApiErrorCode.ProductOptionDoesNotBelongToSelectedProduct:
      return translate('productOptionDoesNotBelongToSelectedProduct');
    case ApiErrorCode.MinimalSelectedOptionCountNotReached:
      return translate('minimalSelectedOptionCountNotReached');
    case ApiErrorCode.MaximalSelectedOptionCountExceeded:
      return translate('maximalSelectedOptionCountExceeded');
    case ApiErrorCode.OrderStatusChangeNotAllowed:
      return translate('orderStatusChangeNotAllowed');
    case ApiErrorCode.CannotChangeTypeOfOrder:
      return translate('cannotChangeTypeOfOrder');
    case ApiErrorCode.ReportTypeNotExist:
      return translate('reportTypeNotExist');
    case ApiErrorCode.PlaceNotFound:
      return translate('placeNotFound');
    case ApiErrorCode.TooManyPeopleForThisSlot:
      return translate('tooManyPeopleForThisSlot');
    case ApiErrorCode.SlotIsAlreadyReserved:
      return translate('slotIsAlreadyReserved');
    case ApiErrorCode.PlaceStatusChangeNotAllowed:
      return translate('placeStatusChangeNotAllowed');
    case ApiErrorCode.PlaceLocationChangesAtSelectedDate:
      return translate('placeLocationChangesAtSelectedDate');
    case ApiErrorCode.AccountPasswordIsNotSet:
      return translate('accountPasswordIsNotSet');
    case ApiErrorCode.AverageReservationTimeIsNotSet:
      return translate('averageReservationTimeIsNotSet');
    case ApiErrorCode.ReservationTimeNotOnTimetable:
      return translate('reservationTimeNotOnTimetable');
    case ApiErrorCode.PlaceSlotsAreIncorrect:
      return translate('placeSlotsAreIncorrect');
    case ApiErrorCode.InvalidMimeType:
      return translate('unacceptableFile');
    case ApiErrorCode.InvalidOwnerStatusChange:
      return translate('invalidOwnerStatusChange');
    case ApiErrorCode.PromotionCannotBeDeleted:
      return translate('promotionCannotBeDeleted');
    case ApiErrorCode.NoSalesRules:
      return translate('noSalesRules');
    case ApiErrorCode.WrongReservationTime:
      return translate('wrongReservationTime');
    case ApiErrorCode.TooLongValueWithDynamicFields:
      return translate('tooLongValueWithDynamicFields');
    case ApiErrorCode.WrongPlaceReservationStatus:
      return translate('wrongPlaceReservationStatus');
    case ApiErrorCode.ReservationsAreCurrentlyPaused:
      return translate('reservationsAreCurrentlyPaused');
    case ApiErrorCode.CannotUpdateBlueMediaDataOfPlace:
      return translate('cannotUpdateBlueMediaDataOfPlace');
    case ApiErrorCode.InvalidBlueMediaHash:
      return translate('invalidBlueMediaHash');
    case ApiErrorCode.CannotGetFormLinkFromBlueMedia:
      return translate('cannotGetFormLinkFromBlueMedia');
    case ApiErrorCode.EmailIsRequiredButNotGiven:
      return translate('emailIsRequiredButNotGiven');
    case ApiErrorCode.TableNotAvailable:
      return translate('tableNotAvailable');
    case ApiErrorCode.TableBookingIsTemporaryDisabled:
      return translate('tableBookingIsTemporaryDisabled');
    case ApiErrorCode.CannotPlaceMoreReservation:
      return translate('cannotPlaceMoreReservation');
    case ApiErrorCode.UserDoesNotHaveCompanyDataSet:
      return translate('userDoesNotHaveCompanyDataSet');
    case ApiErrorCode.OperationIsForbidden:
      return translate('operationIsForbidden');
    case ApiErrorCode.PromotionalCodeIsUsed:
      return translate('promotionalCodeIsUsed');
    case ApiErrorCode.MaximalNumberOfBannersReached:
      return translate('maximalNumberOfBannersReached');
    case ApiErrorCode.OrderValueTooLowToUseThisPromotion:
      return translate('orderValueTooLowToUseThisPromotion');
    case ApiErrorCode.PromotionalCodeNotFound:
      return translate('promotionalCodeNotFound');
    case ApiErrorCode.AccountIsNotAuthorizedToUseCode:
      return translate('accountIsNotAuthorizedToUseCode');
    case ApiErrorCode.PlaceIsNotRegisteredInBlueMedia:
      return translate('placeTypeDoesNotAllowReservation');
    case ApiErrorCode.PlaceTypeDoesNotAllowReservation:
      return translate('placeTypeDoesNotAllowReservation');
    case ApiErrorCode.ProductNameIsRequired:
      return translate('productNameIsRequired');
    case ApiErrorCode.OptionNameIsRequired:
      return translate('optionNameIsRequired');
    case ApiErrorCode.OptionGroupTitleIsRequired:
      return translate('optionGroupTitleIsRequired');
    case ApiErrorCode.PlaceIsNotAcceptedByBlueMedia:
      return translate('placeIsNotAcceptedByBlueMedia');
    case ApiErrorCode.PasswordResetTokenIsInvalid:
      return translate('passwordResetTokenIsInvalid');
    case ApiErrorCode.PlaceDoesNotProvideTableBooking:
      return translate('placeDoesNotProvideTableBooking');
    case ApiErrorCode.PlaceNameIsRequired:
      return translate('placeNameIsRequired');
    case ApiErrorCode.RequestAlreadyExists:
      return translate('requestAlreadyExists');
    case ApiErrorCode.PlanNotAllowedForSelectedPlace:
      return translate('planNotAllowedForSelectedPlace');
    case ApiErrorCode.PlanAlreadyAssigned:
      return translate('planAlreadyAssigned');
    case ApiErrorCode.UserBlocked:
      return translate('UserBlocked');
    case ApiErrorCode.DateFromLowerThanAllowedTime:
      return translate('dateFromLowerThanAllowedTime');
    case ApiErrorCode.DateFromLowerThanCurrentTime:
      return translate('dateFromLowerThanCurrentTime');
    case ApiErrorCode.DiscountValueOutOfRange:
      return translate('discountValueOutOfRange');
    default:
      return translate('occurred');
  }
};
