import { Currency, PromotionalCodeType, UserEntity, ValueType } from 'types';

interface PromotionInput {
  name: string;
  startsAt: Date;
  endsAt: Date;
  value: number;
  // currency: Currency;
  minOrderValue: number;
  priceReductionStrategy: ValueType;
  numberOfCodes?: number | null;
}

export type CreatePromotionReq = PromotionInput;

export type UpdatePromotionReq = PromotionInput;

export interface PromotionEntity {
  id: string;
  name: string;
  startsAt: Date;
  endsAt: Date;
  minOrderValue: number;
  value: number;
  numberOfCodes: number | null;
  numberOfUsed: number | null;
  currency: Currency;
  isPromotionCodeSent: boolean | null;
  priceReductionStrategy: ValueType;
  authorizedUser: UserEntity | null;
  type: PromotionalCodeType;
  createdAt: Date;
}

export interface CreatePromotionTableRes {
  name: string;
  startsAt: Date;
  endsAt: Date;
  value: number;
  currency: Currency;
  numberOfCodes: number | null;
  numberOfUsed: number | null;
  priceReductionStrategy: ValueType;
  minOrderValue: number;
  isPromotionCodeSent: false;
  // group: PromotionGroups;
}

export interface UpdateNumberOfCodes {
  numberOfCodes: number;
}

export enum PromotionGroups {
  ALL = 'ALL',
  FIRST_REGISTER = 'FIRST_REGISTER',
}
