import { FileRes, PlaceTag, PlaceType } from 'types';

export enum BannerTargetType {
  Url = 'URL',
  PlacesByTag = 'PLACES_BY_TAG',
  Place = 'PLACE',
  PlacesByType = 'PLACE_BY_TYPE',
}

export const AllBannerTargetTypes = [
  BannerTargetType.Url,
  BannerTargetType.PlacesByTag,
  BannerTargetType.Place,
  BannerTargetType.PlacesByType,
];

interface BannerTargetTemplate {
  type: BannerTargetType;
}

export interface BannerUrlTarget extends BannerTargetTemplate {
  type: BannerTargetType.Url;
  url: string;
}

export interface BannerPlacesByTagTarget extends BannerTargetTemplate {
  type: BannerTargetType.PlacesByTag;
  tag: PlaceTag;
}

export interface BannerPlaceTarget extends BannerTargetTemplate {
  type: BannerTargetType.Place;
  placeId: string;
}

export interface BannerPlacesByTypeTarget extends BannerTargetTemplate {
  type: BannerTargetType.PlacesByType;
  placeType: PlaceType;
}

export type BannerTarget =
  | BannerUrlTarget
  | BannerPlacesByTagTarget
  | BannerPlaceTarget
  | BannerPlacesByTypeTarget;

export interface BannerPanelData {
  id: string;
  visibleFrom: Date;
  visibleTo: Date;
  image: FileRes;
  createdAt: Date;
  target: BannerTarget;
}
