import { I18nEntry, PlaceEntity, RedirectionTargets } from 'types';

export enum DynamicFieldType {
  USER_NAME = 'USER_NAME',
  USER_SURNAME = 'USER_SURNAME',
}

export const DynamicFieldCharacterCount: Record<DynamicFieldType, number> = {
  [DynamicFieldType.USER_NAME]: 60,
  [DynamicFieldType.USER_SURNAME]: 60,
};

export interface DynamicField {
  position: number;
  fieldType: DynamicFieldType;
}

export interface RedirectionType {
  target: RedirectionTargets;
  placeId: string | null;
}

export interface RedirectionTypeRes extends RedirectionType {
  objectName: I18nEntry | null;
}

export interface RedirectionTypeReq {
  target: RedirectionTargets;
  placeId?: string | null;
}

export type DynamicFieldRes = DynamicField;

export interface PushTemplateEntity {
  id: string;
  internalName: string;
  title: string;
  description: string;
  dynamicFields: DynamicField[];
  redirection: RedirectionType | null;
  redirectionPlace: PlaceEntity | null;
}

export interface PushTemplateReq {
  name: string;
  content: string;
  internalName: string;
  dynamicFields: DynamicField[];
  redirection?: RedirectionTypeReq | null;
}

export interface PushTemplatePanelRes {
  id: string;
  name: string;
  content: string;
  internalName: string;
  dynamicFields: DynamicField[];
  redirection: RedirectionTypeRes | null;
}
