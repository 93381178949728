export enum OrderStatus {
  REQUESTED = 'REQUESTED',
  PAYED = 'PAYED',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  READY_TO_RELEASE = 'READY_TO_RELEASE',
  RELEASED = 'RELEASED',
}

export const ActiveOrderStatuses: OrderStatus[] = [
  OrderStatus.PAYED,
  OrderStatus.CONFIRMED,
  OrderStatus.READY_TO_RELEASE,
];

export const AllOrderStatuses: OrderStatus[] = [
  OrderStatus.REQUESTED,
  OrderStatus.PAYED,
  OrderStatus.CONFIRMED,
  OrderStatus.REJECTED,
  OrderStatus.READY_TO_RELEASE,
  OrderStatus.RELEASED,
];

export const ArchivalOrderStatuses: OrderStatus[] = [
  OrderStatus.REJECTED,
  OrderStatus.RELEASED,
];

// For mobile app:
export const PlannedOrderStatuses: OrderStatus[] = [
  OrderStatus.REQUESTED,
  OrderStatus.PAYED,
  OrderStatus.CONFIRMED,
  OrderStatus.READY_TO_RELEASE,
];

export const HistoricOrderStatuses: OrderStatus[] = [
  OrderStatus.REJECTED,
  OrderStatus.RELEASED,
];

export const NotifiableOrderStatuses: OrderStatus[] = [
  OrderStatus.CONFIRMED,
  OrderStatus.REJECTED,
  OrderStatus.READY_TO_RELEASE,
  OrderStatus.RELEASED,
];
