export enum ReportType {
  ORDERS = 'ORDERS',
  USERS = 'USERS',
  PROMOTIONS = 'PROMOTIONS',
}

export interface ReportOrderRes {
  label: string;
  value: string;
}
